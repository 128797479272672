import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import { addPropertyControls, ControlType } from "framer"
import { useEffect } from "react"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ClientsMetaAdd({ slug, meta }) {
    useEffect(() => {
        const replaceMetaDescription = (description) => {
            let metaTag = document.querySelector('meta[name="description"]')
            if (metaTag) {
                metaTag.content = description
            } else {
                metaTag = document.createElement("meta")
                metaTag.name = "description"
                metaTag.content = description
                document.head.appendChild(metaTag)
            }
        }

        const updateURL = (slug) => {
            const newURL = `${window.location.origin}/clients/${slug}`
            window.history.pushState({ path: newURL }, "", newURL)
        }

        if (meta) replaceMetaDescription(meta)
        if (slug) updateURL(slug)
    }, [slug, meta])

    return (
        <div style={containerStyle}>
            <Example />
        </div>
    )
}

addPropertyControls(ClientsMetaAdd, {
    slug: { type: ControlType.String, title: "Slug" },
    meta: { type: ControlType.String, title: "Meta Description" },
})

const containerStyle = {
    height: "100%",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
